import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="text-left container mx-auto">
            <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
            <p className="text-sm mb-6">
                <strong>Effective Date:</strong> 03/12/2024 | <strong>Last Updated:</strong> 03/12/2024
            </p>

            <p className="mb-4">
                Welcome to Ouranos! By accessing or using our platform, you agree to be bound by these Terms and Conditions.
                If you do not agree, please do not use our services.
            </p>

            <section className="mb-8">
                <h1 className="text-xl font-semibold mb-3">1. Use of Ouranos Services</h1>
                <ul className="list-disc list-inside space-y-2">
                    <li>Ouranos provides weather and astronomical forecasts for informational purposes only.
                        While we strive for accuracy, we cannot guarantee the precision of predictions due to the dynamic nature of weather and astronomical conditions.</li>
                    <li>You agree not to use our services for any unlawful purposes or in violation of these Terms.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h1 className="text-xl font-semibold mb-3">2. Account Responsibilities</h1>
                <ul className="list-disc list-inside space-y-2">
                    <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
                    <li>Any activity under your account is your responsibility. Please notify us immediately if you suspect unauthorized use.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h1 className="text-xl font-semibold mb-3">3. Sharing Your Information</h1>
                <p>
                    Ouranos does not sell or share your personal information with third parties, except in the
                    following cases:
                </p>
                <ul className="list-disc list-inside space-y-2">
                    <li><strong>Legal Compliance:</strong> If required to comply with legal obligations.</li>
                    <li><strong>Service Providers:</strong> To perform essential functions like website hosting and data storage using Google Firebase.</li>
                    <li><strong>Weather API:</strong> We use the Open Meteo API to provide accurate and up-to-date weather forecasts. This API may collect and process data to deliver weather information.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h1 className="text-xl font-semibold mb-3">4. Intellectual Property</h1>
                <ul className="list-disc list-inside space-y-2">
                    <li>All content on Ouranos, including but not limited to forecasts, text, graphics, and software, is the intellectual property of Ouranos unless otherwise stated.</li>
                    <li>You may not reproduce, distribute, or create derivative works based on Ouranos content without explicit permission.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h1 className="text-xl font-semibold mb-3">5. Limitation of Liability</h1>
                <p>
                    Ouranos and its affiliates are not liable for any direct, indirect, incidental, or consequential damages arising from your use of the platform.
                    This includes reliance on weather or astronomical data for planning activities.
                </p>
            </section>

            <section className="mb-8">
                <h1 className="text-xl font-semibold mb-3">6. Modifications to the Service</h1>
                <p>
                    We reserve the right to modify or discontinue the platform or any of its features at any time without notice.
                    You acknowledge that such changes may impact your use of the service.
                </p>
            </section>

            <section className="mb-8">
                <h1 className="text-xl font-semibold mb-3">7. Termination</h1>
                <p>
                    We reserve the right to suspend or terminate your account if you violate these Terms and Conditions or engage in activities that harm Ouranos or its users.
                </p>
            </section>

            <section className="mb-8">
                <h1 className="text-xl font-semibold mb-3">8. Governing Law</h1>
                <p>
                    These Terms are governed by and construed in accordance with the laws of France.
                    Any disputes arising from your use of Ouranos will be subject to the exclusive jurisdiction of the courts in France.
                </p>
            </section>

            <section className="mb-8">
                <h1 className="text-2xl font-bold mb-4">9. General Terms of Sale</h1>

                <div className="mb-6">
                    <h1 className="text-lg font-semibold mb-2">10 Description of Products or Services</h1>
                    <p >
                        Ouranos offers precise and up-to-date weather and astronomical forecasts. A free version,
                        is available with basic features. Access to advanced functionalities requires a subscription.
                    </p>
                </div>

                <div className="mb-6">
                    <h1 className="text-lg font-semibold mb-2">9.2 Pricing and Payment Terms</h1>
                    <p >
                        Service prices are listed in euros and include VAT. Payments can be made via credit card or PayPal through
                        the Stripe payment system. Subscription prices may vary and are subject to change without prior notice.
                    </p>
                </div>

                <div className="mb-6">
                    <h1 className="text-lg font-semibold mb-2">9.3 Delivery and Access</h1>
                    <p>
                        Subscription is activated immediately upon purchase. If you encounter any issues or bugs, please do not hesitate to contact us.
                    </p>
                </div>

                <div className="mb-6">
                    <h1 className="text-lg font-semibold mb-2">9.4 Right of Withdrawal and Refunds</h1>
                    <p >
                        You have a 14-day period to cancel your subscription and request a refund.
                    </p>
                </div>

                <div className="mb-6">
                    <h1 className="text-lg font-semibold mb-2">9.5 Warranties</h1>
                    <p >
                        We strive to provide the most accurate weather forecasts possible; however, due to the unpredictable nature
                        of weather conditions, accuracy cannot be guaranteed.
                    </p>
                </div>

                <div className="mb-6">
                    <h1 className="text-lg font-semibold mb-2">9.6 Liability</h1>
                    <p >
                        Ouranos is not responsible for decisions made based on the provided weather forecasts.
                        We are not liable for failures in the Open Meteo API or the Stripe payment system.
                        We reserve the right to modify subscription prices at any time.
                    </p>
                </div>

                <div className="mb-6">
                    <h1 className="text-lg font-semibold mb-2">9.7 Dispute Resolution</h1>
                    <p >
                        Any disputes will be subject to the jurisdiction of the competent courts of France.
                    </p>
                </div>
            </section>

            {/* Section dédiée à l'API Ouranos */}
            <section className="mb-8">
                <h1 className="text-2xl font-bold mb-4">10. API Ouranos - Terms of Use</h1>

                <div className="mb-6">
                    <h1 className="text-lg font-semibold mb-2">10.1 Description of the API</h1>

                    <p className="mb-4">
                        By subscribing to or using the API, you agree to be bound by these Terms of Use for the API. If you do not agree, you should not use the API or subscribe to the service.
                    </p>

                    <div className="mb-6">
                        <h1 className="text-lg font-semibold mb-2">10.2 Description of the API</h1>
                        <p>
                            Ouranos provides an API (the "API") that offers access to weather and astronomical data specifically tailored for astronomers and related industries.
                            The API allows users to integrate weather forecasts, sky conditions, and other astronomical insights into their own applications and services.
                        </p>
                    </div>

                </div>

                <div className="mb-6">
                    <h1 className="text-lg font-semibold mb-2">10.3 API Usage Restrictions</h1>
                    <ul className="list-disc list-inside space-y-2">
                        <li>The API is intended for non-commercial, personal use only unless a commercial license is explicitly obtained from Ouranos.</li>
                        <li>Excessive use of the API, including exceeding usage limits, may result in rate-limiting or suspension of access to the API.</li>
                        <li>The data provided through the API is subject to copyright and may not be redistributed, sold, or used for commercial purposes without express permission.</li>
                    </ul>
                </div>

                <div className="mb-6">
                    <h1 className="text-lg font-semibold mb-2">10.4 API Pricing</h1>
                    <p>
                        Access to the API is available under various pricing plans, including free and paid options. Subscription fees are recurring, and pricing is based on usage.
                        All fees are displayed on the pricing page and are subject to change. Usage limits are based on the specific plan chosen.
                    </p>
                </div>

                <div className="mb-6">
                    <h1 className="text-lg font-semibold mb-2">10.5 API Access and Availability</h1>
                    <p>
                        While Ouranos strives to provide continuous access to the API, we do not guarantee 100% uptime. We may interrupt or suspend access for maintenance, security, or other reasons as necessary.
                    </p>
                </div>

                <div className="mb-6">
                    <h1 className="text-lg font-semibold mb-2">10.6 API Support</h1>
                    <p>
                        We offer basic support for the API. Any issues or questions regarding the API should be submitted through our support channels. Ouranos will do its best to resolve issues promptly.
                    </p>
                </div>

                <div className="mb-6">
                    <h1 className="text-lg font-semibold mb-2">10.7 API Termination</h1>
                    <p>
                        We reserve the right to terminate or suspend API access at any time if we determine that you are in breach of these terms or if your usage negatively impacts the stability or performance of the API.
                    </p>
                </div>

                <div className="mb-6">
                    <h1 className="text-lg font-semibold mb-2">10.8 API Updates</h1>
                    <p>
                        We may periodically update or change the API. While we strive to minimize disruptions, users must agree to adapt to any changes that occur, including changes in functionality or pricing.
                    </p>
                </div>

                <div className="mb-6">
                    <h1 className="text-lg font-semibold mb-2">10.9 API License</h1>
                    <p>
                        By using the API, you are granted a non-exclusive, non-transferable license to access the API for personal or commercial use, depending on the terms of the selected subscription.
                    </p>
                    <p className="mt-4">
                        When using the Ouranos API, you are required to provide proper attribution. This includes a visible link to the <a href="https://www.ouranos-app.com" target="_blank" rel="noopener noreferrer" className="text-blue-500">Ouranos website</a> (https://ouranos-app.com) and the API Ouranos mention picture, which can be downloaded <a href="https://www.ouranos-app.com/Logo_Ouranos-API.zip" target="_blank" rel="noopener noreferrer" className="text-blue-500">here</a>.
                    </p>
                </div>
            </section>



            <section>
                <h1 className="text-xl font-semibold ">11. Contact Us</h1>
                <p>
                    If you have any questions regarding these Terms and Conditions, please contact us:
                </p>
                <ul className="list-none space-y-2">
                    <li><strong>Email:</strong> <a href="mailto:contact@ouranos-app.com" className="text-blue-600 underline">contact@ouranos-app.com</a></li>
                </ul>
            </section>
        </div>
    );
};

export default TermsAndConditions;
