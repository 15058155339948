import React from 'react';
import tickImage from '../assets/tick.svg';
import { LuFileJson } from "react-icons/lu";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Button, Divider, Accordion, AccordionItem, Tabs, Tab, Tooltip } from "@nextui-org/react";
import { Cpu, ArrowLeft } from 'iconsax-react';
import Footer from '../components/footer';
import Logo from "../assets/logo_API.svg"
import { HiSparkles } from "react-icons/hi2";
import { BiStats } from "react-icons/bi";
import { FiExternalLink } from "react-icons/fi";
import { TbClockHour2 } from "react-icons/tb";
import { FaSun, FaCloud, FaRegClock, FaSatelliteDish } from 'react-icons/fa';



const PricingTable = () => {
    const AIJson = `{
  "daily": [
      {
          "date": "2025-01-23",
          "sunset": "2025-01-23T16:38",
          "sunrise_next": "2025-01-24T07:23",
          "is_observable": true,
          "observation_text": "The best window for stargazing is between 22:00 and 01:00, with the lowest cloud cover at 9%."
      }
  ]
}`;

    const IndexJson = `{
  "hourly": [
    {
      ...
        "time": "2025-01-21T00:00",
      "precipitation_probability": 5,
      "seeing_index": 4,
      "sky_quality_index": 3
    },
    {
      ...
        "time": "2025-01-21T01:00",
      "precipitation_probability": 5,
      "seeing_index": 3,
      "sky_quality_index": 2
    },
    ...`;

    // Fonction pour coloriser et respecter l'indentation
    const colorizeJson = (json) => {
        return json.split("\n").map((line, index) => {
            const trimmedLine = line.trim();

            if (trimmedLine.includes(":")) {
                const [key, value] = trimmedLine.split(/:(.+)/); // Séparer clé et valeur
                return (
                    <div key={index} style={{ marginLeft: `${line.length - trimmedLine.length}px` }}>
                        <span className="text-indigo-400">{key.trim()}</span>:{" "}
                        <span className="text-teal-300">{value.trim()}</span>
                    </div>
                );
            }

            return (
                <div key={index} style={{ marginLeft: `${line.length - trimmedLine.length}px` }}>
                    {line}
                </div>
            );
        });
    };

    return (
        <div>

            <Navbar isBordered >

                <NavbarBrand  >
                    <img src={Logo} alt="Logo" className='w-8' />
                </NavbarBrand>


                <NavbarContent justify="end" >
                    <NavbarItem className="hidden sm:flex gap-4" justify="center" >

                        <Link color="foreground" href="#pricing">
                            Pricing
                        </Link>

                        <Link color="foreground" href="http://ouranos-api.notion.site" isExternal className='flex gap-1'>
                            Documentation
                            <FiExternalLink />
                        </Link>
                    </NavbarItem>
                </NavbarContent>
            </Navbar>

            <div className="flex flex-col sm:flex-col md:flex-row items-start justify-center text-white px-6 lg:px-16 xl:px-32 pt-8 lg:pt-16 gap-12 lg:gap-24 ">
                {/* Left Section */}
                <div className="text-left space-y-6 max-w-lg px-4">
                    <h1 className="text-2xl lg:text-4xl font-extrabold bg-gradient-to-r from-white to-stone-300 bg-clip-text text-transparent">
                        Accurate astronomical predictions tailored for astronomers.
                    </h1>

                    <p className="text-base lg:text-lg text-gray-400">
                        Discover the power of Ouranos API, offering precise astronomical data, including Seeing, Sky Quality Index, and AI-driven recommendations to enhance your sky observations.
                    </p>


                    <div className='flex gap-2' >
                        <Link href="#pricing" passHref>
                            <Button color="primary" className="shiny-button border-1 border-indigo-500 ">
                                Start testing now
                            </Button>
                        </Link>

                        <Link href="http://ouranos-api.notion.site" isExternal>
                            <Button variant='flat' >
                                View documentation
                                <FiExternalLink />

                            </Button>
                        </Link>
                    </div>
                </div>



                {/* Right Section */}
                <div className="flex flex-col w-full max-w-lg px-4 h-[32rem]">

                    <Tabs variant='bordered' className='dark' aria-label="Astronomy Data">

                        <Tab key="stargazing-badge" title={
                            <div className="flex items-center space-x-2">
                                <HiSparkles />

                                <span>AI recommendations</span>
                            </div>
                        } >


                            <div className="bg-gray-900 p-4 rounded-lg text-white">
                                <div className="flex items-center justify-between text-gray-400 gap-1 mb-4 text-sm">
                                    <span className="flex items-center gap-2">
                                        <LuFileJson size={18} /> JSON
                                    </span>
                                </div>
                                <pre className="text-left whitespace-pre-wrap text-xs lg:text-sm">
                                    {colorizeJson(AIJson)}
                                </pre>
                            </div>
                        </Tab>



                        <Tab key="additional-badge" title={
                            <div className="flex items-center space-x-2">
                                <TbClockHour2 />

                                <span>Hourly forecast</span>
                            </div>
                        } >
                            <div className="bg-gray-900 p-4 rounded-lg text-white">
                                <div className="flex items-center justify-between text-gray-400 gap-1 mb-4 text-sm">
                                    <span className="flex items-center gap-2">
                                        <LuFileJson size={18} /> JSON
                                    </span>
                                </div>
                                <pre className="text-left whitespace-pre-wrap text-xs lg:text-sm">
                                    {colorizeJson(IndexJson)}
                                </pre>
                            </div>
                        </Tab>


                    </Tabs>
                </div>
            </div>

            <Divider className='mt-6' />

            <div className="bg-dark text-white py-16">
                <p className=" font-medium text-center mb-6 px-4 text-gray-400 text-xl">Features</p>

                <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 px-4">
                    <div className="p-6 bg-gray-900 rounded-lg shadow-xl lg:h-48 ">
                        <div className="flex items-center mb-4">
                            <FaSun className="text-indigo-300 text-xl mr-2" />
                            <h3 className="text-xl font-semibold text-left">Astronomy Recommendations</h3>
                        </div>
                        <p className="text-base text-left">
                        Get AI-powered recommendations on the best times to observe or set up your equipment. Our API analyzes cloud cover, visibility, and other meteorological parameters to provide a textual recommendation and an indicator for optimal viewing conditions.
                        </p>

                    </div>

                    <div className="p-6 bg-gray-900 rounded-lg shadow-xl lg:h-48 ">
                        <div className="flex items-center mb-4">
                            <FaCloud className="text-indigo-300 text-xl mr-2" />
                            <h3 className="text-xl font-semibold text-left">Hourly Forecasts</h3>
                        </div>
                        <p className="text-base text-left">Access detailed hourly forecasts designed for astronomers, including temperature, wind speed, and key astronomical data like seeing and sky quality indexes.</p>
                    </div>

                    <div className="p-6 bg-gray-900 rounded-lg shadow-xl lg:h-48">
                        <div className="flex items-center mb-4">
                            <FaRegClock className="text-indigo-300 text-xl mr-2" />
                            <h3 className="text-xl font-semibold text-left">Real-time Observability</h3>
                        </div>
                        <p className="text-base text-left">Know when the sky is most clear for stargazing. Our API provides real-time visibility conditions based on current weather and atmospheric factors.</p>
                    </div>

                    <div className="p-6 bg-gray-900 rounded-lg shadow-xl lg:h-48">
                        <div className="flex items-center mb-4">
                            <FaSatelliteDish className="text-indigo-300 text-xl mr-2" />
                            <h3 className="text-xl font-semibold text-left">Comprehensive Data</h3>
                        </div>
                        <p className="text-base text-left">Receive hourly and daily forecasts, including sky quality and seeing indexes, to make the best choices for your astronomical observations.</p>
                    </div>
                </div>
            </div>



            <Divider />

            {/* Pricing Section */}
            <section className=" py-12" id='pricing'>
                <p className="  font-medium text-center mb-6 px-4 text-gray-400 text-xl">Pricing</p>

                <div className="container mx-auto text-center">


                    <stripe-pricing-table
                        pricing-table-id="prctbl_1QkQICPrAXNVsOeUH6udwhCV"
                        publishable-key="pk_live_51QkQAvPrAXNVsOeUnaxshTSS7uT4fAvxbCrJieYVMMkHLjk5ic0A0tdrKFkFCKtvLNIMhXIoVK2GSPQ2pLfhd2oP00jiflGLmQ"
                    ></stripe-pricing-table>
                </div>
            </section>

            <Footer />
        </div >
    );
};

export default PricingTable;
