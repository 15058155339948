import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoPremium from '../assets/logo-premium.svg';
import { useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';
import { getFirestore, doc, getDoc, onSnapshot } from "firebase/firestore";
import { Button, Spinner } from "@nextui-org/react";
import { ArrowRight } from "iconsax-react";

const StripeProducts = ({ user }) => {
    const { t } = useTranslation();
    const [products, setProducts] = useState([]);
    const [promoDetails, setPromoDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(false);
    const [loadingprice, setLoadingPrice] = useState(true);
    const navigate = useNavigate();
    const handleLogin = () => {
        navigate('/myprofile');
    };
    const oneTimeProducts = products.filter(product => !product.recurring);
    const subscriptionProducts = products.filter(product => product.recurring);
    const [trialExpired, setTrialExpired] = useState(false);

    const createCheckoutSession = async (priceId, promoId, mode, applyTrial) => {
        console.log('Mode:', mode); // Log pour vérifier le mode
        console.log('Apply Trial:', applyTrial); // Log pour vérifier si la période d'essai est active

        setLoading(true);
        try {
            const docRef = doc(getFirestore(), 'users', user.uid);
            const docSnap = await getDoc(docRef);
            const customerId = docSnap.data().stripeCustomerId;

            const response = await fetch(
                'https://us-central1-test-ouranos-react.cloudfunctions.net/createCheckoutSession-createCheckoutSession',
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        priceId,
                        customerId,
                        mode,
                        ...(promoId && { promoId }), // Ajoute promoId si défini
                        applyTrial, // Ajoute applyTrial à la requête
                    }),
                }
            );

            if (response.ok) {
                const data = await response.json();
                await Browser.open({ url: data.url });
            } else {
                console.error('Erreur lors de la création du lien de paiement');
            }
        } catch (error) {
            console.error('Erreur lors de la requête de création du lien de paiement', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        // Mise en place de l'écouteur de snapshot Firestore
        const unsubscribe = () => {
            if (user) {
                const userDocRef = doc(getFirestore(), 'users', user.uid);

                // On utilise onSnapshot pour écouter les changements en temps réel
                const unsubscribeFromSnapshot = onSnapshot(userDocRef, (userSnap) => {
                    if (userSnap.exists()) {
                        const userData = userSnap.data();
                        console.log("Updated user data:", userData); // Log pour vérifier les données mises à jour
                        setUserData(userData);  // Mettre à jour les données utilisateur en temps réel
                        setTrialExpired(userData?.trialExpired || false);  // Met à jour l'état `trialExpired` selon les données

                    } else {
                        console.log("No such document!");
                    }
                });

                return unsubscribeFromSnapshot; // On retourne la fonction pour se désabonner quand le composant se démonte
            }
        };

        // Exécuter la fonction de mise en place de l'écouteur
        const unsubscribeSnapshot = unsubscribe();

        // Nettoyage du listener à la destruction du composant
        return () => {
            if (unsubscribeSnapshot) {
                unsubscribeSnapshot();  // Désabonne l'écouteur lorsque le composant se démonte
            }
        };
    }, [user, setUserData]);  // Recharger les données lorsque l'utilisateur change


    useEffect(() => {
        const fetchPromoIdAndDetails = async () => {
            try {
                const promoRef = doc(getFirestore(), 'system', 'promo');
                const promoSnap = await getDoc(promoRef);
                const promoData = promoSnap.data();
                const annualPromoId = promoData.stripePromoId;
                const monthlyPromoId = promoData.stripeMonthlyPromoId;
                const oneTimePromoId = promoData.stripeOneTimePromoId; // Ajouter l'ID promo pour les achats uniques

                let promoDetails = {};

                if (annualPromoId) {
                    promoDetails.annual = await fetchPromotionDetails(annualPromoId);
                }

                if (monthlyPromoId) {
                    promoDetails.monthly = await fetchPromotionDetails(monthlyPromoId);
                }

                if (oneTimePromoId) {
                    promoDetails.oneTime = await fetchPromotionDetails(oneTimePromoId); // Récupérer les détails pour les achats uniques
                }

                setPromoDetails(promoDetails);
                fetchPricesForProduct();
            } catch (error) {
                console.error('Erreur lors de la récupération des détails de la promotion:', error);
            }
        };

        const fetchPromotionDetails = async (promoId) => {
            const functionUrl = 'https://us-central1-test-ouranos-react.cloudfunctions.net/getPromotionDetails-getPromotionDetails';
            const response = await fetch(functionUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ promoId }),
            });

            if (!response.ok) throw new Error('Failed to fetch promotion details');

            return await response.json();
        };

        fetchPromoIdAndDetails();
    }, []);


    const fetchPricesForProduct = async () => {
        const productId = 'prod_MtkAOfNwckM0Q6';
        setLoadingPrice(true);
        try {
            const response = await fetch('https://us-central1-test-ouranos-react.cloudfunctions.net/getStripeProducts', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ productId }),
            });

            if (response.ok) {
                const prices = await response.json();
                setProducts(prices);
            } else {
                console.error('Erreur lors de la récupération des prix Stripe');
            }
        } catch (error) {
            console.error('Erreur lors de la requête', error);
        } finally {
            setLoadingPrice(false);
        }
    };

    const renderProductPrice = (product) => {
        const originalPrice = product.unit_amount;
        let discountedPrice = originalPrice;
        let percentOff = null;

        if (product.recurring?.interval === 'year' && promoDetails?.annual?.coupon?.percent_off) {
            percentOff = promoDetails.annual.coupon.percent_off;
            discountedPrice = originalPrice * (1 - percentOff / 100);
        } else if (product.recurring?.interval === 'month' && promoDetails?.monthly?.coupon?.percent_off) {
            percentOff = promoDetails.monthly.coupon.percent_off;
            discountedPrice = originalPrice * (1 - percentOff / 100);
        } else if (!product.recurring && promoDetails?.oneTime?.coupon?.percent_off) { // Vérifier pour les achats uniques
            percentOff = promoDetails.oneTime.coupon.percent_off;
            discountedPrice = originalPrice * (1 - percentOff / 100);
        }

        return (
            <div>

                <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                    <p style={{ color: percentOff ? '#ffa2a2' : 'var(--white)', fontSize: '22px', margin: '0' }}>
                        {new Intl.NumberFormat(navigator.language, { style: 'currency', currency: product.currency }).format(discountedPrice / 100)}
                    </p>
                    {product.recurring ? (
                        <p style={{ color: percentOff ? '#ffa2a2' : 'var(--white)', fontSize: '16px', margin: '0' }}>
                            {product.recurring?.interval === 'month' ? t('perMonth') : t('perYear')}
                        </p>
                    ) : (
                        <p >

                        </p>
                    )}

                    {percentOff && (
                        <div style={{ background: '#ffa2a2', display: 'flex', alignItems: 'center', width: 'fit-content', paddingInline: '6px', borderRadius: '6px', paddingBlock: '2px' }}>
                            <p style={{ fontWeight: 'bold', color: '#261717', fontSize: '14px', marginBlock: '0' }}>-{percentOff}%</p>
                        </div>
                    )}
                </div>
                {percentOff && (
                    <div>

                        <font style={{ color: 'var(--grey10)', fontSize: '20px', textDecoration: 'line-through' }}>
                            {new Intl.NumberFormat(navigator.language, { style: 'currency', currency: product.currency }).format(originalPrice / 100)}
                        </font>

                    </div>
                )}
            </div>
        );
    };

    const handleButtonClick = () => {
        if (!user) {
            navigate('/myprofile');  // Redirection vers /myprofile si l'utilisateur n'est pas connecté
        } else {
            createCheckoutSession(
                'price_1QT60TLWBmlnz6OqL0773yGt', // ID du produit
                'monthly' === 'monthly' // Interval (fixé ici pour l'exemple)
                    ? promoDetails?.monthly?.coupon?.id
                    : promoDetails?.annual?.coupon?.id,
                'subscription',
                true // Appliquer la période d'essai
            );
        }
    };

    const handlePurchase = async () => {
        if (!user) {
            navigate('/myprofile'); // Redirection vers le profil si l'utilisateur n'est pas connecté
            return;
        }

        setLoading(true); // Démarre le chargement pour désactiver le bouton

        // Récupérer le prix non récurrent (ponctuel) dans les produits
        const price = products
            .filter((product) => !product.recurring) // Filtre l'achat unique
            .map((product) => product.id)[0]; // Prend le premier prix non récurrent (id)

        if (!price) {
            console.error('Aucun prix non récurrent trouvé');
            setLoading(false);
            return;
        }
     


        try {
            const docRef = doc(getFirestore(), 'users', user.uid);
            const docSnap = await getDoc(docRef);
            const customerId = docSnap.data().stripeCustomerId;

            const response = await fetch(
                'https://us-central1-test-ouranos-react.cloudfunctions.net/createCheckoutSession-createCheckoutSession',
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        priceId: price, // Utilise l'ID du prix récupéré
                        customerId: customerId,    // ID Stripe du client
                        mode: 'payment', // Achat unique
                    }),
                }
            );

            if (response.ok) {
                const { url } = await response.json();
                await Browser.open({ url });
            } else {
                console.error('Erreur lors de la création du lien de paiement');
            }
        } catch (error) {
            console.error('Erreur lors de la requête', error);
        } finally {
            setLoading(false); // Fin du chargement
        }
    };




    if (!userData && user) {
        return (
            <div style={{ textAlign: 'center' }}>
                <Spinner size="md" />
            </div>
        );
    }


    // Vérification de l'état de l'essai
    if (trialExpired === false) {
        return (
            <div style={{ zIndex: '1', textAlign: 'center' }}>

                {/* Features Section */}
                <div className="flex flex-col bg-gray-900 p-4 rounded-xl gap-3">

                    <div>
                        <h1 className="text-2xl text-white text-center">{t('startYour14DayTrial')}</h1>
                        {loadingprice ? (
                            <div className="flex justify-center items-center">
                                <Spinner size="md" className='my-6' />
                            </div>
                        ) : (
                            <>
                                <div className="flex flex-row-reverse gap-1 justify-center">
                                    {/* Filtrage des produits non récurrents (achat unique) */}
                                    {products.filter((product) => !product.recurring).map((product) => {
                                        const priceFormatted = new Intl.NumberFormat(navigator.language, {
                                            style: 'currency',
                                            currency: product.currency,
                                        }).format(product.unit_amount / 100);

                                        return (
                                            <div className="flex" key={product.id}>


                                                <p className="text-gray-400 text-sm">
                                                or {`${priceFormatted} on time purchase`}
                                                </p>

                                            </div>
                                        );
                                    })}

                                    {/* Affichage des prix récurrents */}
                                    {products.filter((product) => product.recurring).map((product) => {
                                        const priceFormatted = new Intl.NumberFormat(navigator.language, {
                                            style: 'currency',
                                            currency: product.currency,
                                        }).format(product.unit_amount / 100);

                                        const isMonthly = product.recurring?.interval === 'month';
                                        const isYearly = product.recurring?.interval === 'year';

                                        return (
                                            <div className="flex" key={product.id}>

                                                {isMonthly && (
                                                    <p className="text-gray-400 text-sm">
                                                    {`${priceFormatted}${t('perMonth')}`}
                                                    </p>
                                                )}

                                            </div>
                                        );
                                    })}
                                </div>




                                <iframe
                                    width="100%"
                                    height={200}
                                    className='rounded-md mt-4'
                                    src="https://www.youtube.com/embed/hrtDBkE4Dy8"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>


                                {user ? (
                                    <div className='flex flex-col gap-2 my-6' >
                                        <Button
                                            size='lg'
                                            color="primary"
                                            className="w-full shiny-button border-1 border-indigo-500"
                                            onPress={handleButtonClick}
                                            isLoading={loading}
                                        >

                                            {t('startTrialButton')} {/* Texte localisé du bouton pour utilisateur existant */}
                                        </Button>
                                        <p className='text-sm' >Or</p>

                                        <div>
                                            {products.length > 0 && (
                                                <Button
                                                    className="w-full"
                                                    onPress={handlePurchase}
                                                    isLoading={loading}
                                                >
                                                    {/* Récupère le premier prix non récurrent et l'affiche dans le bouton */}
                                                    {products
                                                        .filter((product) => !product.recurring) // Filtre l'achat unique
                                                        .map((product) => {
                                                            const priceFormatted = new Intl.NumberFormat(navigator.language, {
                                                                style: 'currency',
                                                                currency: product.currency,
                                                            }).format(product.unit_amount / 100);

                                                            // Utilisation de la clé de traduction via i18n et injection du prix
                                                            return t('buyOneTime', { price: priceFormatted });
                                                        })[0]}
                                                </Button>
                                            )}
                                        </div>


                                    </div>
                                ) : (

                                    <Button

                                        size="lg"
                                        color="secondary"
                                        className="my-6 w-full  shiny-button border-1 border-indigo-500"
                                        onPress={handleButtonClick} // Fonction différente pour un utilisateur non existant
                                    >
                                        <ArrowRight />
                                        {t('signup')} {/* Texte localisé pour utilisateur non existant */}
                                    </Button>
                                )}

                                {products[1]?.product?.features && (
                                    <div>
                                        {products[1].product.features.map((feature, index) => (
                                            <p key={index} className="text-left text-gray-400 text-sm">
                                                • {feature.name}
                                            </p>
                                        ))}
                                    </div>
                                )}


                            </>
                        )}
                    </div>






                </div>

            </div>
        );
    }


    return (
        <div style={{ zIndex: '1' }}>

            <div className="flex flex-col bg-gray-900 p-4 rounded-xl">
                <div>
                    <img
                        style={{ width: '140px', margin: 'auto' }}
                        src={LogoPremium}
                        alt="Logo Ouranos Premium"
                    />

                    {loadingprice ? (
                        <div className="flex justify-center items-center">
                            <Spinner size="md" />
                        </div>
                    ) : (
                        <>
                            <h1 className="text-md  text-xl text-center">Subscribe to access all features</h1>


                            {/* Single Purchase Section */}
                            <div >

                                <div>
                                    {oneTimeProducts.map((product) => (
                                        <>

                                            <h2>Single Purchase</h2>
                                            <div key={product.id} className="flex justify-between items-center">
                                                {renderProductPrice(product)}
                                                {user ? (
                                                    <Button
                                                        className={loading ? "btn-primary-loading" : "btn-primary"}
                                                        onPress={() =>
                                                            createCheckoutSession(
                                                                product.id,
                                                                promoDetails?.oneTime?.coupon?.id,
                                                                'payment'
                                                            )
                                                        }
                                                        disabled={loading}
                                                    >
                                                        {loading ? <div className="loaderLogin" /> : t('subscription')}
                                                    </Button>
                                                ) : null}
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>


                            <iframe
                                width="100%"
                                height={200}
                                className='rounded-md my-6'
                                src="https://www.youtube.com/embed/hrtDBkE4Dy8"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>

                            {/* Subscriptions Section */}
                            <h2>{t('subscriptions')}</h2>
                            <div className="flex gap-4 flex-col">
                                {subscriptionProducts.map((product) => (
                                    <div
                                        key={product.id}
                                        className="flex justify-between "
                                        style={{ textAlign: 'left' }}
                                    >
                                        {renderProductPrice(product)}
                                        {user ? (
                                            <Button
                                                color='primary'
                                                className={loading ? "btn-primary-loading" : ""}
                                                onPress={() =>
                                                    createCheckoutSession(
                                                        product.id,
                                                        product.recurring?.interval === 'year'
                                                            ? promoDetails?.annual?.coupon?.id
                                                            : promoDetails?.monthly?.coupon?.id,
                                                        'subscription'
                                                    )
                                                }
                                                disabled={loading}
                                            >
                                                {loading ? <div className="loaderLogin" /> : t('subscription')}
                                            </Button>
                                        ) : null}
                                    </div>
                                ))}
                            </div>


                            {/* Features Section */}
                            {products[1]?.product?.features && (
                                <div className="mt-4">
                                    {products[1].product.features.map((feature, index) => (
                                        <p key={index} className='text-left text-gray-400 text-sm'>
                                            • {feature.name}
                                        </p>
                                    ))}
                                </div>
                            )}



                        </>
                    )}
                </div>



            </div>

        </div>

    );

};

export default StripeProducts;
