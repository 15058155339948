import React, { useState, useEffect } from 'react';
import { auth, sendPasswordResetEmail } from '../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, signInWithRedirect, getRedirectResult, signInWithCredential } from "firebase/auth";
import { doc, setDoc, getFirestore } from 'firebase/firestore'; // Importer les fonctions Firestore
import Signup from "./Signup";
import Logo from '../assets/logo.png'
import { useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';
import { Button, Input, Alert, useDisclosure, Link, Spinner } from "@nextui-org/react";
import { useLocationData } from './GlobalContext';

import { getFirebaseErrorMessage } from "./firebaseErrors"; // Ton fichier de gestion des erreurs
import StripeProducts from './StripeProducts'
import { useNavigate } from 'react-router-dom'; // Import du hook navigate
import { FcGoogle } from "react-icons/fc";
import { Capacitor } from "@capacitor/core"; // Importez Capacitor pour la détection de la plateforme


import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

const Tab4 = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { user, subscription, userData } = useLocationData();
  const [isButtonDisabledManageBtn, setButtonDisabledManageBtn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showSignup, setShowSignup] = useState(false);
  const [alert, setAlert] = useState({ visible: false, message: "", color: "default" });
  const { isOpen, onOpen, onOpenChange } = useDisclosure(); // Gestion de la modal pour l'erreur
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialisation du hook navigate
  const [products, setProducts] = useState([]);
  const [loadingprice, setLoadingPrice] = useState(true);

  const showAlert = (message, color) => {
    setAlert({ visible: true, message, color });
    setTimeout(() => setAlert({ visible: false, message: "", color: "default" }), 5000);
  };

  const handleDeleteAccount = () => {
    if (window.confirm("Are you sure you want to delete your account and all your data? This action is irreversible.")) {
      user.delete().then(function () {
        console.log("User deleted.");
      }).catch(function (error) {
        console.error("Error deleting user:", error);
      });
    }
  };


  const signIn = (e) => {
    e.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = getFirebaseErrorMessage(error);

        showAlert(errorMessage, "danger");

        console.error("Error during sign in:", error);
        setLoading(false);
      });
  };



  const handleSignOut = () => {
    auth.signOut();
  };

  const sendResetEmail = async () => {
    if (!email) {
      showAlert("Please enter your email address.", "warning");
      return;
    }

    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      showAlert("Check your inbox for the password reset email.", "success");
      setLoading(false);
    } catch (error) {
      const errorMessage = getFirebaseErrorMessage(error);
      showAlert(errorMessage, "info");
      console.error("Error sending password reset email:", error);
      setLoading(false);
    }
  };


  const handleSignup = () => {
    setShowSignup(true);
  };

  const handleBackToLogin = () => {
    setShowSignup(false);
  };

  const handleCustomerPortalButtonClick = async () => {
    setLoading(true);
    setButtonDisabledManageBtn(true);
    if (userData?.stripeCustomerId) {
      try {
        const functionUrl = 'https://us-central1-test-ouranos-react.cloudfunctions.net/createCustomerPortail-createCustomerPortail'; // Remplacez par l'URL de votre fonction Firebase

        const response = await fetch(functionUrl, {
          method: 'POST',
          mode: 'cors', // Ajoutez cette option
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ customerId: userData?.stripeCustomerId }),
        });

        if (response.ok) {
          const session = await response.json();
          await Browser.open({ url: session.url });
          setLoading(false);
        } else {
          console.error('Une erreur s\'est produite lors de la création de la session.');
        }
      } catch (error) {
        console.error('Une erreur s\'est produite lors de la requête.', error);
      }
    } else {
      setLoading(false);
      console.error('customerId is empty.');
    }
  };

  const fetchPricesForProduct = async () => {
    const productId = 'prod_MtkAOfNwckM0Q6';
    setLoadingPrice(true);
    try {
      const response = await fetch('https://us-central1-test-ouranos-react.cloudfunctions.net/getStripeProducts', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ productId }),
      });

      if (response.ok) {
        const prices = await response.json();
        setProducts(prices);
      } else {
        console.error('Erreur lors de la récupération des prix Stripe');
      }
    } catch (error) {
      console.error('Erreur lors de la requête', error);
    } finally {
      setLoadingPrice(false);
    }
  };

  const handlePurchase = async () => {
    if (!user) {
      navigate('/myprofile'); // Redirection vers le profil si l'utilisateur n'est pas connecté
      return;
    }

    setLoading(true); // Démarre le chargement pour désactiver le bouton

    // Récupérer le prix non récurrent (ponctuel) dans les produits
    const price = products
      .filter((product) => !product.recurring) // Filtre l'achat unique
      .map((product) => product.id)[0]; // Prend le premier prix non récurrent (id)

    if (!price) {
      console.error('Aucun prix non récurrent trouvé');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        'https://us-central1-test-ouranos-react.cloudfunctions.net/createCheckoutSession-createCheckoutSession',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            priceId: price, // Utilise l'ID du prix récupéré
            customerId: user.stripeCustomerId,    // ID Stripe du client
            mode: 'payment', // Achat unique
          }),
        }
      );

      if (response.ok) {
        const { url } = await response.json();
        await Browser.open({ url });
      } else {
        console.error('Erreur lors de la création du lien de paiement');
      }
    } catch (error) {
      console.error('Erreur lors de la requête', error);
    } finally {
      setLoading(false); // Fin du chargement
    }
  };




  const handleGoogleLogin = async () => {
    try {
      setLoading(true)
      console.log('Initiating Google Sign-In...');

      // Step 1: Initiate Google Sign-In using Capacitor
      const result = await FirebaseAuthentication.signInWithGoogle();
      console.log('Google Sign-In result:', JSON.stringify(result, null, 2));

      // Step 2: Ensure we have a valid ID token for Firebase Auth
      if (result.credential?.idToken) {
        const credential = GoogleAuthProvider.credential(result.credential.idToken);

        // Step 3: Sign in to Firebase Auth with the credential
        const userCredential = await signInWithCredential(auth, credential);
        console.log('Firebase user authenticated:', JSON.stringify(userCredential.user, null, 2));

        // Step 4: Update user data in Firestore
        const { email, uid } = userCredential.user;
        console.log('Updating Firestore with user data...');
        await setDoc(
          doc(getFirestore(), "users", uid),
          {
            email,
            createdAt: new Date(),
            lastLogin: new Date(), // Optional: track the last login time
          },
          { merge: true } // Avoid overwriting existing data
        );
        console.log('User data successfully updated in Firestore.');

        // Step 5: Navigate to the main app page
        console.log('Navigating to /myprofile...');

        navigate('/myprofile');
        setLoading(false)
      } else {
        console.warn('Google Sign-In did not return a valid credential.');
      }
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      setError(error.message); // Show error if needed
      onOpen(); // Optional: open a modal to display the error
    }
  };

  // Gérer le retour de la redirection (pour Capacitor)
  useEffect(() => {
    const fetchRedirectResult = async () => {
      try {
        if (Capacitor.isNativePlatform()) {
          const result = await getRedirectResult(auth);
          if (result) {
            const user = result.user;

            // Ajouter ou mettre à jour l'utilisateur dans Firestore
            await setDoc(
              doc(getFirestore(), "users", user.uid),
              {
                email: user.email,
                createdAt: new Date(),
              },
              { merge: true }
            );

            navigate('/'); // Redirigez vers la page principale
          }
        }
      } catch (error) {
        setError(error.message);
        onOpen();
      }
    };

    fetchRedirectResult();
    fetchPricesForProduct();
  }, []); // Exécuté après le montage du composant


  if (showSignup) {
    return <Signup onBackToLogin={handleBackToLogin} />;
  }

  console.log(subscription)

  if (user) {
    return (

      <div className='min-h-screen pt-8 flex flex-col gap-2 text-left' >

        <h1>{t('helloUser')}, {userData?.username}</h1>

        <p>Email : {userData?.email}</p>

        <p>
          {t('mySubscription', { subscription: subscription === 1 ? 'Premium' : 'Freemium' })}

        </p>



        {subscription !== 1 &&
          <div >

            <div className="md:w-1/2 w-full mx-auto"  >
              <StripeProducts user={user} />
            </div>
          </div>
        }

        <div className='flex flex-col sm:grid sm:grid-cols-3 gap-2 mt-2 ' >

          <div>
            {products.length > 0 && (
              <Button
                className="w-full"
                onPress={handlePurchase}
                isLoading={loading}
              >
                {/* Récupère le premier prix non récurrent et l'affiche dans le bouton */}
                {products
                  .filter((product) => !product.recurring) // Filtre l'achat unique
                  .map((product) => {
                    const priceFormatted = new Intl.NumberFormat(navigator.language, {
                      style: 'currency',
                      currency: product.currency,
                    }).format(product.unit_amount / 100);

                    // Utilisation de la clé de traduction via i18n et injection du prix
                    return t('buyOneTime', { price: priceFormatted });
                  })[0]}
              </Button>
            )}
          </div>
          

          <Button onPress={handleCustomerPortalButtonClick} isDisabled={subscription !== 1}>
            {loading ? (
              <div className="loaderButton" />
            ) : (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '6px' }} >
                <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18">
                  <path fill='var(--white)' d="M182.152-114.022q-27.599 0-47.865-20.265-20.265-20.266-20.265-47.865v-595.696q0-27.697 20.265-48.033 20.266-20.337 47.865-20.337h242.783q14.424 0 24.244 10.012Q459-826.194 459-811.717q0 14.478-9.821 24.174-9.82 9.695-24.244 9.695H182.152v595.696h595.696v-242.783q0-14.424 9.871-24.244Q797.59-459 812.068-459q14.477 0 24.313 9.821 9.837 9.82 9.837 24.244v242.783q0 27.599-20.337 47.865-20.336 20.265-48.033 20.265H182.152Zm181.609-250q-9.326-9.804-9.826-23.385-.5-13.581 9.695-23.963l366.718-366.478H553.065q-14.424 0-24.244-9.871Q519-797.59 519-812.068q0-14.477 9.821-24.313 9.82-9.837 24.244-9.837h258.848q14.394 0 24.349 9.956 9.956 9.955 9.956 24.349v258.848q0 14.424-10.012 24.244Q826.194-519 811.717-519q-14.478 0-24.174-9.821-9.695-9.82-9.695-24.244v-176.283L411.37-362.63q-9.638 9.195-23.591 9.076-13.953-.12-24.018-10.468Z" />
                </svg>
                {t('manageBilling')}
              </div>
            )}
          </Button>


          <Button onPress={handleSignOut}>{t('signOutButton')}</Button>


          <Button color='danger' onPress={handleDeleteAccount}>{t('deleteAccountButton')}</Button>



        </div>


      </div>
    );
  } else {
    return (
      <div className="flex items-start  justify-center h-screen w-full p-4 pt-4 md:pt-16 ">
        <div className="w-full max-w-md">
          <form className="flex flex-col gap-4 w-full" onSubmit={signIn}>
            <img className="w-12 md:w-16 mx-auto" src={Logo} alt="Logo Ouranos" />
            <h1 className="text-center">{t("login")}</h1>


            <Button
              size='lg'
              variant="bordered"
              onPress={handleGoogleLogin}
              className="w-full"
              isLoading={loading}
            >
              <FcGoogle size={24} />
              Continue with Google
            </Button>

            <p className="text-center text-zinc-400">Or</p>

            <Input

              variant='faded'
              size='lg'
              label={t("emailLabel")}
              autoComplete="email"
              type="email"
              placeholder="example@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input

              variant='faded'
              size='lg'
              label={t("passwordLabel")}
              autoComplete="current-password"
              type="password"
              placeholder={t("passwordPlaceholder")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            {alert.visible && (
              <Alert
                color={alert.color}
                title={alert.message}
                className="my-4"
              />
            )}

            <div className="flex flex-col gap-2">
              <Button

                size='lg'
                color="primary"
                className={
                  loading
                    ? "btn-primary-loading w-full"
                    : "btn-primary w-full"
                }
                type="submit"
                disabled={loading}
              >
                {loading ? <div className="loaderLogin" /> : t("login")}
              </Button>

              <div className="flex flex-col md:flex-row justify-between items-center gap-2">
                <p className="text-zinc-400 text-center">
                  {"Don't have an account? "}
                  <Link
                    color="foreground"
                    className="cursor-pointer"
                    onPress={handleSignup}
                  >
                    Signup
                  </Link>
                </p>

                <Link
                  color="foreground"
                  onClick={sendResetEmail}
                >
                  {t("resetpassword")}
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>

    );
  }
};

export default Tab4;
