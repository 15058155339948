import React, { createContext, useState, useContext, useEffect } from 'react';
import { getFirestore, doc, onSnapshot, updateDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { auth, app } from '../firebase'; // Importez vos instances Firebase
import ReactGA from 'react-ga4';



// Créez le contexte
const LocationContext = createContext();

// Fournisseur du contexte
export const LocationProvider = ({ children }) => {
    // Fonction pour initialiser les données à partir de localStorage
    const getInitialLocation = () => {
        const latitude = localStorage.getItem('latitude');
        const longitude = localStorage.getItem('longitude');
        const city = localStorage.getItem('cityName');
        const cacheTimestamp = localStorage.getItem('openMeteoCacheTimestamp');

        return {
            latitude: latitude ? parseFloat(latitude) : null,
            longitude: longitude ? parseFloat(longitude) : null,
            city: city || 'Noirmoutier',
            lastUpdated: cacheTimestamp
        };
    };

    const [location, setLocation] = useState(getInitialLocation);
    const [subscription, setSubscription] = useState(null); // État pour gérer l'abonnement utilisateur
    const [user, setUser] = useState(null); // État pour gérer l'utilisateur
    const [userData, setUserData] = useState(null); // État pour stocker les données de l'utilisateur

    ReactGA.initialize('G-MMXEE4FSXD');





    useEffect(() => {
        // Detect the browser's language
        const language = navigator.language || 'en';  // Default to 'en' if not available
        setLocation(prevLocation => ({ ...prevLocation, language })); // Met à jour la langue dans le state
        localStorage.setItem('language', language);  // Sauvegarde la langue dans le localStorage
    }, []);


    useEffect(() => {
        // Vérifiez si l'utilisateur est celui à exclure
        if (userData?.email !== 'paul.joly03@gmail.com') {
            // Envoyer l'événement uniquement si l'utilisateur n'est pas exclu
            ReactGA.send({
                hitType: 'pageview',
                page: location.pathname,
            });
        }
    }, [location, userData]);

    // Écoutez les changements d'état d'authentification
    useEffect(() => {
        const unsubscribeAuth = auth.onAuthStateChanged(async (currentUser) => {
            setUser(currentUser);

            if (currentUser) {
                const db = getFirestore(app);
                const userRef = doc(db, "users", currentUser.uid);

                // Récupérez les données de l'utilisateur depuis Firestore
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setUserData(userDoc.data()); // Mettez à jour les données de l'utilisateur
                } else {
                    console.log('No user data found');
                    setUserData(null);
                }

                // Mettez à jour le champ lastActive pour l'utilisateur connecté
                try {
                    await updateDoc(userRef, { lastActive: serverTimestamp() });
                } catch (error) {
                    console.error("Erreur lors de la mise à jour de lastActive :", error);
                }

                // Écoutez les mises à jour de l'abonnement utilisateur
                const subscriptionDocRef = doc(db, `users/${currentUser.uid}/ouranos/subscription`);
                const unsubscribeSubscription = onSnapshot(subscriptionDocRef, (docSnap) => {
                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        setSubscription(data.type === 1 ? 1 : 0);
                    } else {
                        setSubscription(null);
                    }
                });

                // Nettoyez l'écouteur de l'abonnement lors de la déconnexion
                return () => unsubscribeSubscription();
            } else {
                setSubscription(null);
                setUserData(null); // Réinitialisez les données de l'utilisateur lors de la déconnexion
            }
        });

        // Nettoyez l'écouteur d'authentification lors du démontage
        return () => unsubscribeAuth();
    }, []);


    // Mettre à jour la langue dans le localStorage si nécessaire
    useEffect(() => {
        if (location.language !== navigator.language) {
            localStorage.setItem('language', navigator.language);
            setLocation((prevLocation) => ({
                ...prevLocation,
                language: navigator.language,
            }));
        }
    }, [navigator.language]);


    return (
        <LocationContext.Provider value={{ location, setLocation, subscription, user, userData }}>
            {children}
        </LocationContext.Provider>
    );
};

// Hook personnalisé pour utiliser le contexte
export const useLocationData = () => useContext(LocationContext);
